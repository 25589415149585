"use client";
import ActionCard, { BrandColor } from "@components/action-card";

export default function Login() {
  return (
    <>
      <div className={"p-4 m-auto max-w-2xl flex gap-x-6"}>
        <ActionCard
          headline={"Customer"}
          headlineColor={BrandColor.RED}
          subtitle={"You buy tickets to comedy shows."}
          actionLink={`${process.env.NEXT_PUBLIC_MEDUSA_BASE_URL}/mct/login/customer?returnTo=${window.location.href}`}
          actionText={"Login"}
        />

        <ActionCard
          headline={"Admin"}
          headlineColor={BrandColor.BLACK}
          subtitle={"You create and manage shows."}
          actionLink={`${process.env.NEXT_PUBLIC_MEDUSA_BASE_URL}/mct/login/admin?returnTo=${process.env.NEXT_PUBLIC_ADS_SERVICE_BASE_URL}/store/set-user-info`}
          actionText={"Login"}
        />
      </div>
    </>
  );
}
